@tailwind base;

html,
body {
  @apply h-full;
}

#root {
  @apply h-full;
}

@tailwind components;

@tailwind utilities;
